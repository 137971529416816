<script>
import DevicesService from '@/service/devices.service';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: 'modal-list-users-device',

  components: {
    ModalViewUserFaceDevice: () => import('./ModalViewUserFaceDevice.vue'),
  },

  data() {
    return {
      dialog: false,
      loading: false,
      device: { name: '' },
      users: [],

      headers: [
        { text: 'ID', value: 'userId' },
        // { text: 'ID no dispositivo', value: 'recNo' },
        { text: 'Nome', value: 'name' },
        // { text: 'Código do Cartão (HEX)', value: 'cardNo' },
        { text: 'Status', value: 'status' },
        // { text: 'Senha', value: 'password' },
        // { text: 'TimeSections[0]', value: 'TimeSections[0]' },
        // { text: 'Use Time', value: 'useTime' },
        { text: 'Acesso a partir', value: 'validDateStart' },
        { text: 'Acesso até', value: 'validDateEnd' },
        { text: 'Ações', align: 'center', value: 'actions' },
      ],
    };
  },

  created() {
    this.$on('viewListUsers', this.viewListUsers);
  },

  methods: {
    viewListUsers(id) {
      this.device = { name: '' };
      this.users = [];
      this.fetch(id);
      this.dialog = true;
    },

    async fetch(id) {
      this.loading = true;

      try {
        const device = await DevicesService.get(id);
        this.device = device;
        const users = await DevicesService.getAllUsers(id);
        this.users = users;
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },

    remove(user) {
      this.$swal({
        text: `Deseja remover '${user.name}'?`,
        icon: 'warning',
        confirmButtonText: 'SIM',
        showDenyButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return DevicesService.deleteUserInDevice(this.device.id, user.userId);
        },
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire({
              icon: 'success',
              title: 'Removido!',
              text: 'Usuário removido com sucesso!',
              timer: 2000,
              showConfirmButton: false,
            });
            this.fetch(this.device.id);
          }
        })
        .catch(() => {
          this.$swal.close();
        });
    },

    viewUserFaceDevice(user) {
      this.$refs.modalViewUserFaceDevice.$emit('viewUserFaceDevice', { device: this.device, user });
    },
  },
};
</script>

<template>
  <v-dialog v-model="dialog" max-width="1000px" :persistent="loading">
    <v-card>
      <v-card-title>
        <span class="text-h5">Usuários do dispositivo {{ device.name }}</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-data-table
            :headers="headers"
            :items="users"
            :loading="loading"
            :footer-props="{ 'items-per-page-options': [10, 30, 50, 100] }"
            class="elevation-0 no-line-row"
          >
            <template v-slot:[`item.cardNo`]="{ item }">
              <span>{{ item.cardNo | empty }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div class="actions">
                <v-btn icon color="error" title="Remover" @click="remove(item)">
                  <v-icon small> mdi-delete </v-icon>
                </v-btn>
                <v-btn icon color="success" title="Ver foto" @click="viewUserFaceDevice(item)">
                  <v-icon small> mdi-image-filter-center-focus-weak </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
    </v-card>
    <modal-view-user-face-device ref="modalViewUserFaceDevice" />
  </v-dialog>
</template>

<style scoped>
.actions {
  min-width: 80px;
}
</style>
